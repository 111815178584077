import React, { useEffect, useState } from "react"
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import JobContent from "../components/JobContent"
import SearchJob from "../components/SearchJob"
import { Seo } from "../components/seo"
import useJobs from "../hooks/useJobs"
const JobsTypesTemplate = props => {
  const dataJobs = useJobs("Japanese")
  const dataJobLocation = props.data.allStrapiLocations.nodes
  const [dataGroupLoc, setDataGroupLoc] = useState([])
  useEffect(() => {
    dataJobs.forEach(element => {
      for (let j = 0; j < element.locations.length; j++) {
        if (dataJobLocation[0].name === element.locations[j].name) {
          setDataGroupLoc(prevState => [...prevState, element])
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Seo
        title={`job-location: ${dataJobLocation[0].name} | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
      />
      <section className="container jobs-listing">
        <div className="row">
          <div className="col-lg-9">
            <div className="jobs-listing__search">
              <SectionTitle>メキシコ求人・就職・転職情報</SectionTitle>
              <hr className="u-line-bottom" />
              <div className="jobs-listing__search--box">
                <SearchJob />
              </div>
            </div>
            <div
              className="jobs-listing__recommended-jobs"
              style={{ padding: "0" }}
            ></div>
            <div className="jobs-listing__job-info">
              <SectionTitle>
                {dataJobLocation[0].name} : {dataGroupLoc.length} 件
              </SectionTitle>
              <hr className="u-line-bottom" />
              {dataGroupLoc !== undefined ? (
                <>
                  {dataGroupLoc.map(jobs => (
                    <>
                      {jobs.locations.map(locations => (
                        <>
                          {dataJobLocation[0].name === locations.name ? (
                            <JobContent jobs={jobs} />
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                    </>
                  ))}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default JobsTypesTemplate
export const query = graphql`
  query jobLocation($slug: String!) {
    allStrapiLocations(filter: { name: { eq: $slug } }) {
      nodes {
        name
      }
    }
  }
`
